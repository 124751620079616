<template>
    <header>
      <HelloWorld msg="您的账号已经激活" />
    </header>
    <main>
      <div class="centered">
        <a>您已经通过邮箱验证，欢迎使用Edit with AI，祝您有一个好的体验！</a>
        <a>将在{{count}}秒后自动跳转到登录页面</a>
      </div>
    </main>

  </template>
  
  <script setup lang="ts">
  import HelloWorld from '../components/HelloWorld.vue'
  import { ref, onMounted, defineProps } from 'vue'
  import { useRouter } from 'vue-router'
  import axios from 'axios'
  import { GetCookie } from '../utils/GetCookie'

  const props = defineProps({
    path: Array
  })
  const count = ref(5)
  const globalerror = ref(false)
  

  
  const startCountdown = () => {
    let intervalId = window.setInterval(() => {
      if (count.value > 0) {
        count.value--
      }else if(count.value==0){
        window.location.href = "/login";
      } else {
        window.clearInterval(intervalId)
        // redirect to login page
      }
    }, 1000)
  }
  
  onMounted(() => {
    startCountdown();

})
  </script>
  
  <style scoped>
  header {
    line-height: 1.5;
    margin-top: 2rem;
    max-height: 100vh;
    font-family: 'Roboto', sans-serif;
  }
  
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    animation: fadeIn 2s;
  }
  
  a {
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    user-select: none;
  }
  
  a:hover {
    background-color: transparent;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @media (min-width: 1024px) {
    header {
      margin-top: 2rem;
      display: flex;
      place-items: center;
      padding-right: calc(var(--section-gap) / 2);
      justify-content: space-between;
    }
  
    .logo {
      margin: 0 2rem 0 0;
      transition: all 0.3s ease;
    }
  
    .logo:hover {
      transform: scale(1.1);
      box-shadow: none;
    }
  
    header .wrapper {
      display: flex;
      place-items: flex-start;
      flex-wrap: wrap;
    }
  }
  </style>