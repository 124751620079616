<template>
  <header>
    <HelloWorld msg="欢迎注册" />
  </header>
  <main>
    <div class="centered">
      <a>您的验证邮件已经发送到了您的邮箱，请检查您的邮箱</a>
      <a>如果找不到邮件请检查您的垃圾邮件或核对您的邮箱是否正确，您可以在{{ count }}秒后重试</a>
      <el-button type="warning" v-if="count === 0" @click="resendEmail">重新发送邮件</el-button>
    </div>
  </main>
</template>

<script setup lang="ts">
import HelloWorld from '../components/HelloWorld.vue'
import { ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import { ElCard ,ElButton} from "element-plus";
import { GetCookie } from '../utils/GetCookie'
const props = defineProps({
  path: String
});


const count = ref(60)

let intervalId: number | null = null
onMounted(() => {
  intervalId = window.setInterval(() => {
    if (count.value > 0) {
      count.value--
    } else {
      if (intervalId !== null) window.clearInterval(intervalId)
    }
  }, 1000)
})

onUnmounted(() => {
  if (intervalId !== null) window.clearInterval(intervalId)
})

const resendEmail = async () => {
  let csrftoken = GetCookie('csrftoken')
  try {
    const response = await axios.post("/api/v2/auth/resendactivemail", {
      'ID':props['path']
    }, {
      headers: {
        'X-CSRFToken': csrftoken // replace 'token' with your token value
      }
    });
  }
  catch (error) {
    console.log(error);
  }
}

</script>

<style scoped>
header {
  line-height: 1.5;
  margin-top: 2rem;
  max-height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  animation: fadeIn 2s;
}

a {
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  user-select: none;
  /* Prevent text from being selected */
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  header {
    margin-top: 2rem;
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
    justify-content: space-between;
  }

  .logo {
    margin: 0 2rem 0 0;
    transition: all 0.3s ease;
  }

  .logo:hover {
    transform: scale(1.1);
    box-shadow: none;
    /* Remove shadow on hover */

  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }
}</style>

