<template>
    <header>
        <HelloWorld msg="密码重置" />
    </header>
    <main v-if="successed">
        <div class="centered">
      <a class="text">您的验证邮件已经发送到了您的邮箱，请检查您的邮箱</a>
    </div>
    
    </main>
    <main v-else>
        <el-card class="box-card">
            <template #header>
                <div class="card-header">
                    <a><span style="font-size: 20pt;">Edit with AI</span><br><span style="font-size: 17.5pt;">the Most
                            Advanced
                            NEXT Generation of Editor </span>
                    </a>
                </div>
            </template>
            <div class="login-container">
                <form @submit.prevent="changePassword">
                    <div class="form-group" v-if="!isLogined">
                        <a for="username">昵称或者邮箱 : </a>
                        <el-input size="large" type="text" id="username" v-model="username" placeholder="输入你的账号" required />
                        <div v-if="username && !isUsernameValid" class="invalid-font">用户名格式错误</div>
                    </div>

                    <div class="form-group">
                        <a for="newPassword">新密码 : </a>
                        <el-input size="large" :type="showPassword ? 'text' : 'password'" id="newPassword"
                            v-model="newPassword" placeholder="输入你的新密码" required>
                            <template #append>
                                <el-button  @click="showPassword = !showPassword"><el-icon><View /></el-icon></el-button>
                            </template>
                        </el-input>
                        <div v-if="newPassword && !isPasswordValid" class="invalid-font">密码格式错误</div>
                    </div>
                    <el-button class="login-button" type="success" @click="changePassword" round>
                        <span v-if="isLoading">验证中...</span>
                        <span v-else>开始验证</span>
                    </el-button>
                    <p class="error">{{ globalerror }}</p>

                </form>
            </div>
        </el-card>
    </main>
</template>
<script setup lang="ts">
// 使用 `<script setup>` 语法简化组件的写法

import HelloWorld from "../components/HelloWorld.vue";
import { ref, computed, watchEffect } from "vue";
import { ElCard, ElButton, ElInput } from "element-plus";
import axios from "axios";
import { GetCookie } from '../utils/GetCookie'

const username = ref("");
const newPassword = ref("");
const globalerror = ref("");
const isLoading = ref(false);
const isLogined = ref(false);
const count = ref(60);
const successed = ref(false);
const isUsernameValid = computed(() => {
    return username.value.length >= 3;
});
const isPasswordValid = computed(() => {
    const regexes = [
        /[a-z]/, // Lowercase letters
        /[A-Z]/, // Uppercase letters
        /[0-9]/, // Numbers
        /[^a-zA-Z0-9]/ // Special characters
    ];

    const matches = regexes.filter(regex => regex.test(newPassword.value));
    return newPassword.value.length >= 6 && matches.length >= 2;
});
watchEffect(() => {
    if (!isUsernameValid.value) {
        console.log("Invalid username");
    }
    if (!isPasswordValid.value) {
        console.log("Invalid password");
    }
});
const showPassword = ref(false);

const changePassword = async () => {

    if ((isUsernameValid.value||isLogined.value) && isPasswordValid.value) {
        isLoading.value = true;
        let csrftoken = GetCookie('csrftoken');
        try {
            const response = await axios.post("/api/v2/auth/changepassword", {
                username: username.value,
                newPassword: newPassword.value,
            }, {
                headers: {
                    'X-CSRFToken': csrftoken,
                }
            });
            if (response.data.state === 'ok') {
                globalerror.value = "修改成功";
                isLoading.value = false;
                successed.value = true;
            } else {
                globalerror.value = response.data.message;
                isLoading.value = false;
            }
        } catch (error) {
            console.error('Error checking login status:', error);
        }
    }
};
const checkLoginStatus = async () => {
    try {
        let csrftoken = GetCookie('csrftoken');
        const response = await axios.post('/api/v2/auth/checklogin',{},{
            headers: {
                    'X-CSRFToken': csrftoken,
                }
        });
        if (response.data.state === 'ok') {
            isLogined.value = true;
        } else {
            isLogined.value = false;
        }
    } catch (error) {
        console.error('Error checking login status:', error);
    }
};

checkLoginStatus();


</script>
  
<style scoped>
header {
    line-height: 1.5;
    margin-top: rem;
    max-height: 100vh;
}

.logo {
    display: block;
    margin: 0 auto 2rem;
}


/* 样式化登录表单 */
.login-container {
    max-width: 300px;
    margin: 0 auto;

}

form {
    display: flex;
    flex-direction: column;
}
.text {
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  user-select: none;
  /* Prevent text from being selected */
}
.form-group {
    margin-bottom: 10px;
}

label {
    font-weight: bold;
}

input {
    padding: 5px;
    margin-top: 5px;
}

button {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

Text {
    color: #147900;
}

label {
    color: #000000;
}

@keyframes flyIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.box-card {
    animation: flyIn 1s ease-out;
    width: 380px;
    border-width: 0;
    border-radius: 30px;
    display: block;
    margin: 0 auto 2rem;
    margin-top: 30px;
    padding: 20px;

}

.el-card {
    transition: transform 0.3s ease;

}

.el-card:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
}

.login-button {

    background-color: #0091ff;
    /* Change to your preferred color */
    color: white;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #0056b3;
    /* Darker shade for hover state */
}

.login-button:active {
    background-color: #003d80;
    /* Even darker shade for active state */
}

.error {
    color: red;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  animation: fadeIn 2s;
}

@media (min-width: 1024px) {

    header {
        margin-top: 2rem;
        display: flex;
        place-items: center;
        padding-right: calc(var(--section-gap) / 2);
    }

    .logo {
        margin: 0 2rem 0 0;
    }

    header .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    .box-card {
        background-color: #00000010;
        border-width: 0;
        border-radius: 30px;
        display: block;
        margin: 0 auto 2rem;
        width: 480px;
    }
}

    .dark-theme .box-card {
        background-color: #00000010;

    }


    .light-theme .box-card {
        background-color: #f4faf2;

    }


.invalid-font {
    color: red;
    font-size: 10pt;
}

a:hover {
    box-shadow: none;
}
main{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  