<template>
  <el-row gutter="20">
    <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="template in templates" :key="template.name">
      <el-card :body-style="{ padding: '20px' }" @click.native="selectTemplate(template.name)">
        <div style="margin-top: 20px; text-align: center;">
          <h3>{{ template.name }}</h3>
          <p>{{ template.description }}</p>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  import { GetCookie } from '@/utils/GetCookie';
  const templates = ref([]);
  const router = useRouter();
  
  onMounted(async () => {
    try {
      const language = 'zh'; // 举例，你可以根据需要动态设置这个值
      let csrftoken = GetCookie('csrftoken'); // 获取CSRF token
      const response = await axios.post('/api/v2/templates/gettemplates', {
        language: language
      }, {
        headers: {
          'X-CSRFToken': csrftoken // 在请求头中加入CSRF token
        }
      });
      templates.value = response.data.templates; // 确保这里与你的API响应匹配
    } catch (error) {
      // 错误处理逻辑
    }
});
  function selectTemplate(templateName) {
    // 使用查询参数传递templateName
    router.push({ path: '/editor', query: { templateName: templateName } });
  }
  </script>
  
  <style scoped>
  /* 卡片基础样式 */
  .el-card {
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  /* 鼠标悬浮效果 */
  .el-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 16px rgba(0,0,0,0.2);
  }
  
  /* 图片样式 */
  .template-image img {
    width: 100%;
    height: auto;
    display: block; /* 防止图片下方出现空隙 */
  }
  
  /* 标题样式 */
  h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  /* 描述文本样式 */
  p {
    color: #666;
    font-size: 14px;
  }
  .dark-theme .el-card {
  background-color: #333;
  color: #fff;
}

.dark-theme h3, .dark-theme p {
  color: #fff;
}

.dark-theme .el-card:hover {
  box-shadow: 0 12px 16px rgba(255,255,255,0.2);
}

/* 浅色主题样式 */
.light-theme .el-card {
  background-color: #fff;
  color: #333;
}

.light-theme h3, .light-theme p {
  color: #333;
}

.light-theme .el-card:hover {
  box-shadow: 0 12px 16px rgba(0,0,0,0.2);
}
  </style>