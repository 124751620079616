<template>
  <div class="chat-container">
    <div class="messages">
      <div v-for="message in messages" :key="message.id" :class="message.type">
        <!-- 头像行 -->
        <div class="message-avatar">
          <el-icon size="40">
            <template v-if="message.type === 'user'">
              <UserFilled />
            </template>
            <template v-else>
              <Monitor />
            </template>
          </el-icon>
          <span>{{ message.type }}</span>
        </div>
        <!-- 消息文本行 -->
        <div class="message-text">
          {{ message.text }}
        </div>
      </div>
    </div>
    <div class="input-container">
      <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type your message..." />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>

<script setup>
import { ref,onMounted } from 'vue';
import axios from 'axios';
import { inject } from 'vue';
import {GetCookie} from "@/utils/GetCookie";
const vditor = inject('vditor');
const selectedText = inject('selectedText');

const newMessage = ref('');
const messages = ref([]);

const sendMessage = async () => {
  let knowledgebaseid = GetCookie('knowledgebaseid'); 
  if(knowledgebaseid == ''){
    knowledgebaseid=0;  
  }
  if (newMessage.value.trim() !== '') {
    const userMessage = { id: messages.value.length + 1, text: newMessage.value, type: 'user' };
    messages.value.push(userMessage);
    newMessage.value = '';

    try {
      const response = await axios.post('api/v2/baiduwenxin/chatcopilot', {
        text: vditor.getValue(),
        selected : selectedText.value,
        question: userMessage.text, // Replace this with the actual question if needed
        knowledgebaseid: knowledgebaseid
      });
      messages.value.push({
        id: messages.value.length + 1,
        text: response.data,
        type: 'bot'
      });
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error, e.g., show an error message to the user
    }
  }
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  max-width: 100%;
  margin-left:0;
  margin-right:0;
  margin-top:0;
  margin-bottom:0;
  background-color: #f9f9f9;
  position: relative;

  flex-grow: 1;
  /* Allow this element to grow and shrink as needed */
  overflow-y: auto;
  /* Enable scrolling if the content overflows */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100%;
  padding: 0;
  font-size: 15px;
  color: #999;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

}
.dark-theme .chat-container {
  background-color: #1a1a1a;
  color: #fff;
}
.messages {
  flex: 1;
  overflow-y: auto;
  overflow-y: auto;
  width:100%;
}

.message {
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  width:calc(100%-20px);

}

.user {
  text-align: left;
  font-family: 'Noto Sans SC', sans-serif;
}
.dark-theme .user {
  background-color: #222222;
  color: #fff
}
.light-theme .user {
  background-color: #f0f0f0;
  color: #111;
}
.bot {
  text-align: left;
  font-family: 'Noto Sans SC', sans-serif;
}
.dark-theme .bot {
  background-color: #333;
  color: #fff;
}
.light-theme .bot {
  background-color: #f5f5f5;
  color: #111;
}

.input-container {
  display: flex;

}

input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

button {
  padding: 0 15px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.message-avatar img {
  width: 40px; /* 头像的宽度 */
  height: 40px; /* 头像的高度 */
  border-radius: 50%; /* 圆形头像 */
  margin-right: 10px; /* 与消息文本的间距 */
}

.message-avatar {
  display: flex;
  align-items: center;
  justify-content: start;
  border-width: 1px;
  border-color: #999;
}

.message-avatar .el-icon {
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px; /* 头像和文本之间的间距 */
  width: 30px; /* 减小头像的尺寸 */
  height: 30px; /* 减小头像的尺寸 */
  margin-left:15px;

}

.message-text {
  margin-top: 2px; /* 文本与头像行之间的间距 */
  padding: 15px;
  font-size: 1.1vw
}
</style>
