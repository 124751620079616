<template>
  <nav>
    <router-link to="/" class="router-link-class">主页</router-link>
    <router-link to="/editor" class="router-link-class">编辑器</router-link>
    <router-link to="/document" class="router-link-class">文档</router-link>
    <router-link to="/templateshop" class="router-link-class">模板</router-link>
    <el-button v-if="!ifLogined" type="primary" class="router-link-class login-button" @click="$router.push('/login')">登录</el-button>
    <el-button v-if="!ifLogined" type="primary" class="router-link-class reg-button" @click="$router.push('/register')">注册</el-button>
    
    <el-dropdown v-if="ifLogined" style="margin-left:12px;margin-right: 30px;margin-top: 6px;"  @visible-change="">
      <a style="font-size: 1rem;    font-family: 'Noto Sans SC', sans-serif;">
        <el-icon><User /></el-icon>
        {{ username.length > 12 ? username.slice(0, 10) + '...' : username }}
            </a>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item disabled ><a>{{  }}</a> </el-dropdown-item>
          <el-dropdown-item @click="goToMyDocuments">我的文档</el-dropdown-item>
          <el-dropdown-item @click="logout">登出</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

    <el-switch
      v-model="isDarkTheme"
      active-color="#f1c40f"
      inactive-color="#34495e"
      class="moon-sun-switch"
      @change="toggleTheme"
    >
    <template #active-action>
      <el-icon><Sunny style="color: #151515;"/></el-icon>

    </template>
    <template #inactive-action>
      <el-icon><Moon style="color: #151515;"/></el-icon>

    </template>
    </el-switch>
  </nav>
  <router-view  class="appdiv"/>
</template>

<style>
body{
  height: 100vh;
}
.router-link-class{
  margin: 0 0.8vw;
}
.moon-sun-switch{
  margin: 0 2vw;
}

.login-button {
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 0.5vw 0 5vw;
  border-radius: 20px;
  font-family: 'Noto Sans SC', sans-serif;

}
.reg-button {
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 0.5vw;
  border-radius: 20px;
  font-family: 'Noto Sans SC', sans-serif;

}
.light-theme nav {
  text-align: right;
  color: #2c3e50;
  padding: 15px;
  height: 32px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.light-theme nav a {
  font-weight: bold;
  color: #2c3e50;
}

.light-theme nav a.router-link-exact-active {
  color: #42b983;
}
.light-theme body {
  background-color: #f6f6f6;
  margin: 0;
}
.dark-theme nav {
  text-align: right;
  color: #ffffff;
  padding: 15px;
  background-color: #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dark-theme nav a {
  font-weight: bold;
  color: #e8e9e9;
}

.dark-theme nav a.router-link-exact-active {
  color: #42b983;
}
.dark-theme body {
  background-color: #161616;
  margin: 0;
}
.dark-theme h1 {
  color: #dbdbdb;
}


.dark-theme .login-button {
  background-color: #ffffff;
  color: black;
}
.dark-theme .reg-button {
  background-color: #ffffff;
  color: black;
}

.light-theme .login-button {
  background-color: #000000;
  color: #ffffff;

}

.light-theme .reg-button {
  background-color: #000000;
  color: #ffffff;

}

</style>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import "element-plus/dist/index.css";
import { setThemeFromCookieOrSystem, toggleTheme } from "./utils/Theme";
import { Moon, Sunny } from "@element-plus/icons-vue";
import { GetCookie } from "@/utils/GetCookie";
import axios from "axios";
import router from "@/router";

const isDarkTheme = ref(false);
let sessionid = GetCookie('sessionid');
const ifLogined = ref(sessionid.length > 0);
const userLevel = ref(0);
const username = ref("");


onMounted(async () => {
  ifLogined.value = sessionid.length > 0;
  console.log("ifLogined: " + ifLogined.value);
  console.log("sessionid: " + sessionid);
  setThemeFromCookieOrSystem();
  getuserbasicInformation();
  isDarkTheme.value = document.body.classList.contains("dark");
  const font = document.createElement("link");
  font.href =
    "https://fonts.googleapis.com/css2?family=Noto+Sans+SC&display=swap";
  font.rel = "stylesheet";
  font.crossOrigin = "anonymous";
  document.body.appendChild(font);

});


const logout = async () => {
  let csrftoken = GetCookie('csrftoken');
  console.log("logout");
  const response = await axios.post("/api/v2/logout", {
  }, {
    headers: {
      'X-CSRFToken': csrftoken,
    }
  });
  if (response.data["state"] == "ok") {
    window.location.href = "/";
  }

};

const getuserbasicInformation = async () => {
  if (ifLogined.value) {
    let csrftoken = GetCookie('csrftoken');
    try {
      const response = await axios.post("/api/v2/informations/getuserbasicinformation", {
      }, {
        headers: {
          'X-CSRFToken': csrftoken,
        }
      });
      if (response.data["state"] == "ok") {
        username.value = response.data["username"];
        userLevel.value = response.data["userLevel"];
        ifLogined.value = true;
        document.cookie = `username=${username.value}; path=/`;
        document.cookie = `userLevel=${userLevel.value}; path=/`;
        console.log("get user basic information success");
      } else {
        ifLogined.value = false;
        console.log("error: " + response.data["msg"]);
      }
    } catch (error) {
      ifLogined.value = false;
    }
  }
}


const goToProfile = async () => {
    router.replace("/personalcenter")  ;
  }
const goToMyDocuments = async () => {
  router.replace("/mydocuments")  ;
}

</script>
