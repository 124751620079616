import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Editor from "../views/Editor.vue";
import Document from "../views/Document.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import ActivedView from "@/views/ActivedView.vue";
import Denied from "@/views/Denied.vue";
import RegisterWelcomeView from "@/views/RegisterWelcomeView.vue";
import MyDocuments from "@/views/MyDocuments.vue";
import TemplateShop from "@/views/TemplateShop.vue";
import { register } from "register-service-worker";

import uploadFile from "@/adminview/uploadFile.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: 'Edit with AI首页' }, // 添加 meta 信息

  },
  {
    path: "/editor/:articleId",
    name: "editor2",
    component: Editor,
    meta: { title: 'Edit with AI' }, // 添加 meta 信息

  },
  {
    path: "/editor",
    name: "editor",
    component: Editor,
    meta: { title: 'Edit with AI' }, // 添加 meta 信息

  },
  {
    path: "/document",
    name: "document",
    component: Document,
    meta: { title: 'Edit with AI文档' }, // 添加 meta 信息

  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: 'Edit with AI登录' }, // 添加 meta 信息

  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { title: 'Edit with AI注册' }, // 添加 meta 信息

  },
  {
    path: "/mydocuments",
    name: "mydocuments",
    component: MyDocuments,
    meta: { title: 'Edit with AI我的文档' }, // 添加 meta 信息

  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: ChangePassword,
    meta: { title: 'Edit with AI找回密码' }, // 添加 meta 信息

  },
  {
    path: '/actived',
    name: 'actived',
    component: ActivedView,
    props: route => ({ path: route.params.pathMatch }),
    meta: { title: 'Edit with AI激活' }, // 添加 meta 信息

  },{
    path: "/denied",
    name: "denied",
    props: route => ({ path: route.params.pathMatch })  ,
    component: Denied,
    meta: { title: 'Edit with AI拒绝' }, // 添加 meta 信息

  },
  {
    path: '/register/welcome',
    name: 'welcome',
    component: RegisterWelcomeView,
    props: route => ({ path: route.params.pathMatch }),
    meta: { title: 'Edit with AI欢迎' }, // 添加 meta 信息

  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/denied'
  },{
    path: "/templateshop",
    name: "templateShop",
    component: TemplateShop,
    meta: { title: 'Edit with AI模板' }, // 添加 meta 信息

  },{
    path: "/admin/uploadFile",
    name: "uploadFile",
    component: uploadFile,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
