<template>
  <el-container class="main-container">
    <!-- 添加切换侧边栏显示状态的按钮 -->
    <el-button class="toggle-sidebar-btn" @click="toggleSidebar">
      <el-icon><Expand/></el-icon>
    </el-button>
    <el-aside :class="{'is-hidden': isSidebarHidden}" width="200px" class="aside-css">
      <el-menu @select="fetchMarkdown">
        <el-menu-item v-for="file in files" :key="file" :index="file">
          {{ file.slice(0, -3) }}
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main v-html="markdownContent"></el-main>
  </el-container>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import MarkdownIt from 'markdown-it';

const files = ref<string[]>([]);
const markdownContent = ref<string>('');
const isSidebarHidden = ref(true); // 控制侧边栏显示状态的变量

const md = new MarkdownIt();

// 获取Markdown文件列表
async function fetchFiles() {
  try {
    const requestBody = {
      language: 'zh', // Adjust the language as needed
    };
    const response = await fetch('/api/v2/articles/getdocumentlist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) throw new Error('Failed to fetch files');
    const data = await response.json();
    files.value = data.files; // Assuming the response structure includes a "files" array
    if (files.value.includes('README.md')) {
      fetchMarkdown('README.md');
    }
  } catch (error) {
    console.error(error);
  }
}

// 获取并渲染Markdown文件内容
async function fetchMarkdown(fileName: string) {
  try {
    const requestBody = {
      language: 'zh', // Adjust the language as needed
      path: fileName,
    };
    const response = await fetch('/api/v2/articles/getdocuments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) throw new Error('Failed to fetch markdown content');
    const data = await response.json();
    markdownContent.value = md.render(data.text); // Assuming the response structure includes a "text" field
  } catch (error) {
    console.error(error);
  }
}
function toggleSidebar() {
  isSidebarHidden.value = !isSidebarHidden.value;
}

onMounted(() => {
  fetchFiles();
});
</script>
<style scoped>
.main-container{
  height: calc(100vh - 32px);
}
.aside-css{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  height: calc(100vh - 32px);

}
.dark-theme .el-aside{
  background-color: #333;
  color: #fff;
  font-family: 'Noto Sans SC', sans-serif;

}
.dark-theme .main-container{
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Noto Sans SC', sans-serif;
}
.dark-theme .el-menu-item{
  color: #fff;
  background-color: #333;
}

.light-theme .el-aside {
  background-color: #f0f0f0;
  color: #000;
  font-family: 'Noto Sans SC', sans-serif;
}

.light-theme .main-container {
  background-color: #fff;
  color: #000;
  font-family: 'Noto Sans SC', sans-serif;
}

.light-theme .el-menu-item {
  color: #000;
  background-color: #f0f0f0;
}

.toggle-sidebar-btn {
  display: none; /* 默认不显示切换按钮 */
  background-color: transparent;
  border-color: #333;

}

/* 在手机端显示切换按钮，并设置侧边栏默认隐藏 */
@media (max-width: 768px) {
  .toggle-sidebar-btn {
    display: block; /* 显示切换按钮 */
    /* 按钮样式调整 */
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 100;
  }

  .aside-css.is-hidden {
    transform: translateX(-100%); /* 隐藏侧边栏 */
    transition: transform 0.3s;
  }

  .aside-css {
    position: fixed;
    z-index: 99;
    height: 100%;
  }
}
</style>