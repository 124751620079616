/* eslint-disable */

import { GetCookie } from "./GetCookie";

export const toggleTheme = () => {
  // 从 cookie 中获取当前主题
  const currentTheme = GetCookie("theme");
  if (currentTheme === "dark") {
    // 如果当前主题是 dark，那么切换到 light 主题
    document.documentElement.classList.remove("dark-theme");
    document.documentElement.classList.add("light-theme");

    // 更新 cookie
    document.cookie = "theme=light; path=/";
  } else {
    // 如果当前主题是 light 或者没有设置主题，那么切换到 dark 主题
    document.documentElement.classList.remove("light-theme");
    document.documentElement.classList.add("dark-theme");

    // 更新 cookie
    document.cookie = "theme=dark; path=/";
  }
};
export function setThemeFromCookieOrSystem() {
  // 从 cookie 中读取 theme
  let theme = document.cookie
    .split("; ")
    .find((row) => row.startsWith("theme="))
    ?.split("=")[1];

  document.documentElement.classList.remove("dark-theme");
  document.documentElement.classList.remove("light-theme");

  if (!theme) {
    // 如果 cookie 中没有 theme，就检查系统的颜色方案
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      theme = "dark";
    } else {
      theme = "light";
    }
    // 将系统的颜色方案保存到 cookie
    document.cookie = `theme=${theme}; path=/`;
  }

  // 使用 theme 设置主题
  document.documentElement.classList.add(theme + "-theme");
}
