
<template>
  <main> <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <a><span style="font-size: 20pt;">Edit with AI</span><br><span style="font-size: 17.5pt;">the Most Advanced NEXT
              Generation of Editor </span>
          </a>
        </div>
      </template>
      <div class="login-container">
        <form @submit.prevent="Register">
          <div class="form-group">
            <a for="username">昵称 : </a>
            <el-input size="large" type="text" id="username" v-model="username" placeholder="输入你的昵称" required />
            <transition name="slide">
              <el-card v-if="!isUsernameValid && username" class="error-card">
                <p class="error"><el-icon><CircleCloseFilled /></el-icon> 用户名不足2个字</p>
              </el-card>
            </transition>
          </div>
          <div class="form-group">
            <a for="email">邮箱 : </a>
            <el-input size="large" type="email" id="email" v-model="email" placeholder="请输入你的邮箱" required />
            <transition name="slide">
              <el-card v-if="!isEmailValid && email" class="error-card">
                <p class="error"><el-icon><CircleCloseFilled /></el-icon> 邮箱格式错误</p>
              </el-card>
            </transition>
          </div>
          <div class="form-group">
            <a for="password">密码 : </a>
            <el-input size="large" :type="showPassword1 ? 'text' : 'password'" id="password" v-model="password"
              placeholder="输入你的密码，不小于6位" required>
              <template #append>
                <el-button @click="showPassword1 = !showPassword1"><el-icon>
                    <View />
                  </el-icon></el-button>
              </template>
            </el-input>
          </div>
          <div class="form-group">
            <transition name="slide">
              <el-card v-if="!isPasswordValid && password" class="error-card">
                <!-- <p v-if="!isPasswordValid && password" class="error">错误！！！</p> -->
                <p v-if="!isPasswordLengthValid && password" class="error"><el-icon><CircleCloseFilled /></el-icon> 密码长度不足6个字符</p>
                <p v-if="passwordRules && !isPasswordValid && password" class="error"><el-icon><CircleCloseFilled /></el-icon> {{ passwordRules }}</p>
              </el-card>
            </transition>
            <a for="password2">密码 : </a>
            <el-input size="large" :type="showPassword2 ? 'text' : 'password'" id="password2" v-model="password2"
              placeholder="请验证你的密码" required>
              <template #append>
                <el-button @click="showPassword2 = !showPassword2"><el-icon>
                    <View />
                  </el-icon></el-button>
              </template>
            </el-input>
            <transition name="slide">
              <el-card v-if="password2 && !isPassword2Valid" class="error-card">
                <p class="error"><el-icon><CircleCloseFilled /></el-icon> 两次输入密码不相同</p>
              </el-card>
            </transition>
          </div>

          <el-button class="register-button" type="success" @click="Register" round :disabled="isLoading">
            <span v-if="isLoading">注册中...</span>
            <span v-else>注册</span>
          </el-button>
          <p class="error">{{ globalerror }}</p>
        </form>
      </div>
    </el-card>
  </main>
</template>


<script setup lang="ts">
import HelloWorld from "../components/HelloWorld.vue";
import { ref, computed, watchEffect } from "vue";
import { ElMessage } from "element-plus";
import { ElCard, ElButton, ElInput, ElFormItem } from "element-plus";
import axios from "axios";
import { GetCookie } from '../utils/GetCookie'
import router from "@/router";

const username = ref("");
const password = ref("");
const password2 = ref("");
const email = ref("");
const showPassword1 = ref(false);
const showPassword2 = ref(false);
const usernameError = ref("");
const passwordError = ref("");
const emailError = ref("");
const isLoading = ref(false);
const globalerror = ref("");
const isPassword2Valid = computed(() => {
  return password2.value == password.value;
});
const isUsernameValid = computed(() => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  const hasAtCharacter = username.value.includes('@');
  return username.value.length >= 2 && !emailRegex.test(username.value) && !hasAtCharacter && username.value.length <= 20;
});

const isPasswordValid = computed(() => {
  const regexes = [
    /[a-z]/, // Lowercase letters
    /[A-Z]/, // Uppercase letters
    /[0-9]/, // Numbers
    /[!@#$%^&*(),.?":'{}|<>\-_]/ // Special characters
  ];

  const matches = regexes.filter(regex => regex.test(password.value));
  return password.value.length >= 6 && matches.length >= 2;
});
const passwordRules = computed(() => {
  const rules = [
    { regex: /[!@#$%^&*(),.?":'{}|<>\-_]/, error: "密码仅包含了特殊字符" },
    { regex: /[0-9]/, error: "密码仅包含了数字" },
    { regex: /[a-z]/, error: "密码仅包含了小写字母" },
    { regex: /[A-Z]/, error: "密码仅包含了大写字母" },
    // ... 其他可能存在的验证规则
  ];
  let matches = 0;
  for (let i = 0; i < 4; i++) {
    if (rules[i].regex.test(password.value))
      matches++;
  }
  if (matches == 1) {
    for (let i = 0; i < 4; i++) {
    if (rules[i].regex.test(password.value))
      return rules[i].error;
    }
  }
});
const isPasswordLengthValid = computed(() => {
  if (password.value.length >= 6)
    return true;
  else
    return false;
});

const isEmailValid = computed(() => {
  const regexes = [
    /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/ // Email format
  ];

  const matches = regexes.filter(regex => regex.test(email.value));
  return email.value.length >= 6 && matches.length >= 1;
});

const Register = async () => {
  if (isEmailValid.value && isUsernameValid.value && isPasswordValid.value && isPassword2Valid.value) {
    isLoading.value = true;
    let csrftoken = GetCookie('csrftoken');
    console.log("Logging in with:", {
      username: username.value,
      password: password.value,
      email: email.value,
      csrftoken: csrftoken
    });
    try {
      const response = await axios.post("api/v2/register", {
        username: username.value,
        password: password.value,
        email: email.value,
      }, {
        headers: {
          'X-CSRFToken': csrftoken // replace 'token' with your token value
        }
      });
      if (response.data["state"] == "ok") {
        ElMessage({
          message: "注册成功",
          type: "success",
        });
        router.replace("/register/welcome/");

      } else if (response.data["state"] == "error") {
        ElMessage({
          message: "注册失败，" + response.data["msg"],
          type: "error",
        });
        globalerror.value = response.data["msg"];
      } else {
        ElMessage({
          message: "注册失败，未知错误",
          type: "error",
        });
        globalerror.value = "未知错误";
      }
      console.log("Registration successful:", response.data);
    } catch (e) {
      console.log(e);
      ElMessage({
        message: "注册失败，网络错误",
        type: "error",
      });

    }
    isLoading.value = false;
  }
}
</script>


<style scoped>
header {
  line-height: 1.5;
  margin-top: rem;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

.error {
  color: red;
  /* margin-top: 1px; */
}

/* 样式化登录表单 */
.login-container {
  max-width: 300px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}

input {
  padding: 5px;
  margin-top: 5px;
}

button {
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

Text {
  color: #45a049;
}

label {
  color: #45a049;
}

.register-button {

  background-color: #0091ff;
  /* Change to your preferred color */
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #0056b3;
  /* Darker shade for hover state */
}

.register-button:active {
  background-color: #003d80;
  /* Even darker shade for active state */
}

@keyframes flyIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0%);
    opacity: 1;

  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

.box-card {
  animation: flyIn 1s ease-out;
  width: 300px;
  border-width: 0;
  border-radius: 30px;
  display: block;
  margin: 0 auto 2rem;
  margin-top: 30px;
  padding: 20px;

}

.error-card {
  animation: slideDown 0.5s ease-out;
  width: auto;
  height: auto;
  border: none;
  /* border-radius: 0; */
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  
}
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-leave-to {
  animation: slideUp 0.5s ease-out;
}

/* 定义关键帧动画 */
@keyframes slideFadeOut{
   from{
     opacity:1;
     transform:translateY(0);
   }
   to{
     opacity:0;
     transform:translateY(-50%);
   }
}
.el-card {
  transition: transform 0.3s ease;

}

.el-card:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

@media (min-width: 1024px) {
  header {
    margin-top: 2rem;
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  .box-card {
    width: 480px;
    background-color: #00000010;
    border-width: 0;
    border-radius: 30px;
    display: block;
    margin: 0 auto 2rem;
  }
}

  .dark-theme .box-card {
    background-color: #00000010;
  }
  .dark-theme .error-card {
    background-color: #090909;
  }



  .light-theme .box-card {
    background-color: #f4faf2;

  }
  .light-theme .error-card {
    background-color: #f4faf2;
  }
main{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-theme span{
  color: white;
}
</style>
