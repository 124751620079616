<template>
    <div>
      <el-select v-model="selectedKnowledgebase" placeholder="请选择知识库">
        <el-option
          v-for="item in knowledgebases"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-upload
        class="upload-demo"
        action="api/v2/knowledge/uploadfile"
        :before-upload="beforeUpload"
        :on-success="handleSuccess"
        :on-error="handleError">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import  {GetCookie} from "@/utils/GetCookie";
const selectedKnowledgebase = ref('');
const knowledgebases = ref([]);

const fetchKnowledgebases = async () => {
  let csrftoken = GetCookie('csrftoken');
  try {
    const response = await fetch('/api/v2/knowledge/listKnowledgebase', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonResponse = await response.json();
    if (jsonResponse.code === 0) { // 确保响应码为0，表示成功
      knowledgebases.value = jsonResponse.data.map(kb => ({
        value: kb.id,
        label: kb.name
      }));
    } else {
      throw new Error(jsonResponse.msg || 'Error fetching knowledgebases');
    }
  } catch (error) {
    console.error('Failed to fetch knowledgebases:', error);
  }
};

onMounted(() => {
  fetchKnowledgebases();
});

const beforeUpload = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('knowledgebase', selectedKnowledgebase.value);

  uploadFile(formData);
  return false; // 阻止文件的自动上传
};

const uploadFile = (formData) => {
  let csrftoken = GetCookie('csrftoken');
  axios.post('api/v2/knowledge/uploadfile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': csrftoken
    }
  }).then(response => {
    // 假设上传文件成功后的响应中包含文件的hashid
    const hashid = response.data.hashid;
    if (hashid) {
      // 构造请求体，将文件保存到知识库
      const saveData = {
        hashid: hashid,
        knowledgefields: [selectedKnowledgebase.value]
      };
      let csrftoken = GetCookie('csrftoken');
      axios.post('/api/v2/knowledge/addfiletoknowledgebase', saveData,{
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        }
      })
        .then(saveResponse => {
          console.log('File saved to knowledgebase successfully:', saveResponse);
          // 这里可以调用handleSuccess，或者定义一个新的处理函数
          handleSuccess(saveResponse);
        })
        .catch(saveError => {
          console.error('Error saving file to knowledgebase:', saveError);
          // 这里可以调用handleError，或者定义一个新的处理函数
          handleError(saveError);
        });
    } else {
      throw new Error('File hashid is missing in the response');
    }
  }).catch(error => {
    handleError(error);
  });
};
const handleSuccess = (response) => {
  ElMessage.success('上传成功');
};

const handleError = (error) => {
  console.error('Upload error:', error);
    ElMessage.error('上传失败');
};
</script>

<style scoped>
/* 添加你的样式 */
</style>