<template>
  <main>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <a><span style="font-size: 20pt;">Edit with AI</span><br><span style="font-size: 17.5pt;">the Most Advanced NEXT
              Generation of Editor </span>
          </a>
        </div>
      </template>
      <div class="login-container">
        <form @submit.prevent="login">
          <div class="form-group">
            <a for="username">昵称 : </a>
            <el-input size="large" type="text" id="username" v-model="username" placeholder="输入你的账号" required />
            <transition name="slide">
              <el-card v-if="username && !isUsernameValid" class="error-card">
                <div class="invalid-font"><el-icon><CircleCloseFilled /></el-icon> 用户名不足2个字</div>
              </el-card>
            </transition>
          </div>
          <div class="form-group">
            <a for="password">密码 : </a>
            <el-input size="large" :type="showPassword ? 'text' : 'password'" id="password" v-model="password" placeholder="******" required >
              <template #append>
                <el-button  @click="showPassword = !showPassword"><el-icon><View /></el-icon></el-button>
            </template>
            </el-input>
            <transition name="slide">
              <el-card v-if="password && !isPasswordValid" class="error-card">
                <p v-if="!isPasswordLengthValid && password" class="invalid-font"><el-icon><CircleCloseFilled /></el-icon> 密码长度不足6个字符</p>
                <p v-if="passwordRules && !isPasswordValid && password" class="invalid-font"><el-icon><CircleCloseFilled /></el-icon> {{ passwordRules }}</p>
              </el-card>
            </transition>
          </div>
          <router-link to="/changepassword" style="margin-left: auto;">忘记密码?</router-link>     
          <el-button class="login-button" type="success" @click="login" round :disabled="isLoading">
            <span v-if="isLoading">登录中...</span>
            <span v-else>登录</span>
          </el-button>
          <p class="error">{{ globalerror }}</p>
        </form>
      </div>
    </el-card>
  </main>
</template>
<script setup lang="ts">
// 使用 `<script setup>` 语法简化组件的写法

import HelloWorld from "../components/HelloWorld.vue";
import { ref, computed, watchEffect } from "vue";
import { ElCard, ElButton, ElInput } from "element-plus";
import axios from "axios";
import { ElMessage } from "element-plus";
const username = ref("");
const password = ref("");
const globalerror = ref("");
const isLoading = ref(false);
const showPassword = ref(false);

const isUsernameValid = computed(() => {
  return username.value.length >= 2;
});

function GetCookie(name: string) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return '';
}
const isPasswordValid = computed(() => {
  const regexes = [
    /[a-z]/, // Lowercase letters
    /[A-Z]/, // Uppercase letters
    /[0-9]/, // Numbers
    /[!@#$%^&*(),.?":'{}|<>\-_]/ // Special characters
  ];

  const matches = regexes.filter(regex => regex.test(password.value));
  return password.value.length >= 6 && matches.length >= 2;
});
const passwordRules = computed(() => {
  const rules = [
    { regex: /[!@#$%^&*(),.?":'{}|<>\-_]/, error: "密码仅包含了特殊字符" },
    { regex: /[0-9]/, error: "密码仅包含了数字" },
    { regex: /[a-z]/, error: "密码仅包含了小写字母" },
    { regex: /[A-Z]/, error: "密码仅包含了大写字母" },
    // ... 其他可能存在的规则
  ];
  let matches = 0;
  for (let i = 0; i < 4; i++) {
    if (rules[i].regex.test(password.value))
      matches++;
  }
  if (matches == 1) {
    for (let i = 0; i < 4; i++) {
    if (rules[i].regex.test(password.value))
      return rules[i].error;
    }
  }
});
const isPasswordLengthValid = computed(() => {
  if (password.value.length >= 6)
    return true;
  else
    return false;
});

watchEffect(() => {
  if (!isUsernameValid.value) {
    console.log("Invalid username");
  }
  if (!isPasswordValid.value) {
    console.log("Invalid password");
  }
});

const login = async () => {

  if (isUsernameValid.value && isPasswordValid.value) {
    let csrftoken = GetCookie('csrftoken');
    isLoading.value = true;

    console.log("Logging in with:", {
      username: username.value,
      password: password.value,
      csrftoken: csrftoken
    });
    try{
      const response = await axios.post("api/v2/login", {
      username: username.value,
      password: password.value,
    }, {
      headers: {
        'X-CSRFToken': csrftoken // replace 'token' with your token value
      },
      timeout: 10000
    });
    if (response.data["state"] == "ok") {
      ElMessage.success("登录成功");
      window.location.href = "/";
    }
    else if (response.data["state"] == "error") {
      globalerror.value = response.data["msg"];
      ElMessage.error(response.data["msg"]);
    }
    else {
      globalerror.value = "未知错误";
    }
    } catch (error) {
      globalerror.value = "网络错误";
      ElMessage.error("网络错误");
    }


  } else {
    console.log("Invalid input");
  }
  isLoading.value = false;

};
</script>

<style scoped>
header {
  line-height: 1.5;
  margin-top: rem;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}


/* 样式化登录表单 */
.login-container {
  max-width: 300px;
  margin: 0 auto;
  
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}

input {
  padding: 5px;
  margin-top: 5px;
}

button {
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

Text {
  color: #147900;
}

label {
  color: #000000;
}

@keyframes flyIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0%);
    opacity: 1;

  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

.box-card {
  animation: flyIn 1s ease-out;
  width: 300px;
  border-width: 0;
  border-radius: 30px;
  display: block;
  margin: 0 auto 2rem;
  margin-top: 30px;
  padding: 20px;

}

.error-card {
  animation: slideDown 0.5s ease-out;
  width: auto;
  height: auto;
  border: none;
  /* border-radius: 0; */
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  
}
.light-theme .error-card {
  background-color: white;
}
.dark-theme .error-card {
  background-color: black;
}
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-leave-to {
  animation: slideUp 0.5s ease-out;
}

.el-card {
  transition: transform 0.3s ease;

}

.el-card:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.login-button {

  background-color: #0091ff;
  /* Change to your preferred color */
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
  /* Darker shade for hover state */
}

.login-button:active {
  background-color: #003d80;
  /* Even darker shade for active state */
}

.error {
  color: red;
}

@media (min-width: 1024px) {

  header {
    margin-top: 2rem;
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  .box-card {
    background-color: #00000010;
    border-width: 0;
    border-radius: 30px;
    display: block;
    margin: 0 auto 2rem;
    width: 480px;
  }
}
  .dark-theme .box-card {
    background-color: #00000010;

  }
  

  .light-theme .box-card {
    background-color:#f4faf2;

  }
  

.invalid-font {
  color: red;
  font-size: 10pt;
}
a:hover {
  box-shadow: none;
}

main{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dark-theme span{
  color: white;
}
</style>
