<template>
    <el-row>
      <el-col :span="12">
        <div class="articles-column">
          <h2>我的文档</h2>
          <el-card
            class="article-card"
            v-for="article in myArticles.data"
            :key="article.id"
            :body-style="{ padding: '20px' }"
            @click="() => jumpToArticle(article.id)"
          >
            <h3>{{ article.title }}</h3>
            <div>创建时间: {{ new Date(article.create_time).toLocaleDateString() }}</div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="articles-column">
          <h2>分享的文档</h2>
          <el-card
            class="article-card"
            v-for="article in sharedArticles.data"
            :key="article.id"
            :body-style="{ padding: '20px' }"
            @click="() => jumpToArticle(article.id)"
          >
            <h3>{{ article.title }}</h3>
            <div>创建时间: {{ new Date(article.create_time).toLocaleDateString() }}</div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </template>
  

<script setup>
import { onMounted, reactive } from 'vue';
import { GetCookie } from "@/utils/GetCookie";
import { ElRow, ElCol, ElCard } from 'element-plus';
import router from '@/router';
// Initialize reactive objects for articles
const myArticles = reactive({ data: [] });
const sharedArticles = reactive({ data: [] });

// Function to list shared articles
const listSharedArticles = async () => {
  try {
    const csrftoken = GetCookie('csrftoken');
    const response = await fetch('/api/v2/articles/listsharedarticle', {
      method: 'POST',
      headers: {
        'X-CSRFToken': csrftoken,
        'Accept': 'application/json',
      },
    });
    if (!response.ok) throw new Error('Failed to list shared articles');
    const data = await response.json();
    sharedArticles.data = data.articles; // Assuming the response has an articles array
  } catch (error) {
    console.error(error);
  }
};

// Function to list my articles
const listMyArticles = async () => {
  try {
    const csrftoken = GetCookie('csrftoken');
    const response = await fetch('/api/v2/articles/listmyarticle', {
      method: 'POST',
      headers: {
        'X-CSRFToken': csrftoken,
        'Accept': 'application/json',
      },
    });
    if (!response.ok) throw new Error('Failed to list my articles');
    const data = await response.json();
    myArticles.data = data.articles; // Assuming the response has an articles array
  } catch (error) {
    console.error(error);
  }
};

// Call the functions on component mount
onMounted(async () => {
  await listSharedArticles();
  await listMyArticles();
});

const jumpToArticle = (articleId) => {
  // Redirect to the article page with the article ID
  // You can use router.push('/article/' + articleId) if you are using Vue Router
  console.log('Jumping to article with ID:', articleId);
  router.replace(`/editor/${articleId}`);

};
</script>

<style scoped>
.dark-theme .article-card{
    background-color: #333;
    color: white;
    margin-bottom: 20px;
    font-family: 'Noto Sans SC', sans-serif;
    border-radius: 20px;
    padding: 0 20px;
    border-width: 3px;
    margin-left: 7vw;
    margin-right: 7vw;
}

.dark-theme h2{
    font-family: 'Noto Sans SC', sans-serif;
    text-align: center;
    color: white;
}
.light-theme .article-card {
    background-color: #fff; /* Light background */
    color: #333; /* Dark text for contrast */
    margin-bottom: 20px;
    font-family: 'Noto Sans SC', sans-serif;
    border-radius: 20px;
    padding: 0 20px;
    border: 3px solid #ccc; /* Lighter border color */
    margin-left: 7vw;
    margin-right: 7vw;
}

.light-theme h2 {
    font-family: 'Noto Sans SC', sans-serif;
    text-align: center;
    color: #333; /* Dark text color */
}

</style>